// import { useHistory, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { removeProperty, fetchPortfolioSettingsByPropertyId } from 'reducers/portfolioSettingsReducer';
import { addPropertyToPortfolio, fetchPortfolio, getIsPropertyInPortfolio } from 'reducers/portfolioReducer';

 
// import './AddToPortfolioButton.css';

const AddToPortfolioButton = (props) => {
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	let {
		isLoading,
		userId,
		propertyData,
		isLoadingPortfolio,
		isRemovingProperty,
		isLoggedIn,
		propertyInPortfolio,
		isLoadingIsPropertyInPortfolio,
	} = useSelector(state => ({
		isLoading: state.property.isLoading || state.address.isLoading,
		userId: state.user.userData?.id,
		propertyData: state.property.propertyData,
		isLoadingPortfolio: state.portfolio.isLoading || state.portfolioSettings.isLoading || state.portfolio.isLoadingIsPropertyInPortfolio,
		isRemovingProperty: state.portfolioSettings.isRemovingProperty,
		isLoggedIn: !!state.user.userData?.id,
		propertyInPortfolio: state.portfolio.propertyInPortfolio,
		isLoadingIsPropertyInPortfolio: state.portfolio.isLoadingIsPropertyInPortfolio,
	}));
	let isPropertyInPortfolio = !!propertyInPortfolio;
	let isLoadingAnything = isLoading || isLoadingPortfolio || !propertyData?.id || isRemovingProperty;
	const [currentPropId, setCurrentPropId] = useState(null);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (isLoggedIn && propertyData?.id && propertyData.id !== currentPropId) {
			setCurrentPropId(propertyData?.id);
			if (!isLoadingIsPropertyInPortfolio) {
				dispatch(getIsPropertyInPortfolio(propertyData.id));	
			}
		}
	}, [userId, isLoggedIn, propertyData?.id]);


	if (!userId) {
		return null;
	}


	const handleRemoveProperty = (propertyId) => {
		dispatch(removeProperty(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
			dispatch(getIsPropertyInPortfolio(propertyId));
		});
	}

	const handleAddProperty = (propertyId) => {
		dispatch(addPropertyToPortfolio(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
			dispatch(getIsPropertyInPortfolio(propertyId));
		});
	}

	let addText = 'Add to Portfolio';
	let removeText = 'Remove from Portfolio';

	if (isLoadingAnything) {
		addText = 'Portfolio';
		removeText = 'Portfolio';
	}

	let togglePortfolioButton = (
		<button className={`btn btn-${isLoadingAnything ? 'secondary' : 'primary'} btn-sm`} disabled={isLoadingAnything} onClick={() => handleAddProperty(propertyData.id)}>
			{isMobile ? (<i className={`bi bi-plus-square`} />) : addText}
		</button>
	);

	if (!isLoadingPortfolio && isPropertyInPortfolio) {
		togglePortfolioButton = (
			<button className={`btn btn-${isLoadingAnything ? 'secondary' : 'danger'} btn-sm`} disabled={isLoadingAnything} onClick={() => handleRemoveProperty(propertyData.id)}>
				{isMobile ? (<i className={`bi bi-dash-square`} />) : removeText}
			</button>
		);
	}


	return (
		togglePortfolioButton
	);	


}

export default AddToPortfolioButton;
