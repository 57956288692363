import { useEffect, useState } from 'react';

import './AlertsCard.css';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import Spinner from 'components/Spinner';
import AlertsList from 'components/AlertsList';
import {
	// addPropertyToPortfolio,
	// fetchPortfolio,
	getIsPropertyInPortfolio
} from 'reducers/portfolioReducer';



function AlertsCard() {
	const dispatch = useDispatch();
	const {
		isLoggedIn,
		propertyData,
		propertyInPortfolio,
		isLoading,
		isLoadingIsPropertyInPortfolio
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		propertyData: state.property.propertyData,
		propertyInPortfolio: state.portfolio.propertyInPortfolio,
		isLoading: state.portfolio.isLoading || state.portfolio.isLoadingIsPropertyInPortfolio,
		isLoadingIsPropertyInPortfolio: state.portfolio.isLoadingIsPropertyInPortfolio,
	}));

	const isPropertyInPortfolio = !!propertyInPortfolio && propertyInPortfolio === propertyData.id;
	const [currentPropId, setCurrentPropId] = useState(null);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (isLoggedIn && propertyData?.id && propertyData.id !== currentPropId) {
			setCurrentPropId(propertyData?.id);
			if (!isLoadingIsPropertyInPortfolio) {
				dispatch(getIsPropertyInPortfolio(propertyData.id));	
			}
		}
	}, [isLoggedIn, propertyData?.id]);

	let content = (
		<div className="alerts-card-content d-flex justify-content-center">
			<Link className="btn btn-primary mt-5" to={`/sign-in`}>Get alerts</Link>
		</div>
	);

	if (isLoading) {
		content = (
			<div className="alerts-card-content d-flex justify-content-center">
				<Spinner />
			</div>
		);
	} else if (!isLoggedIn) {
		content = (
			<div className="alerts-card-content d-flex justify-content-center">
				<Link className="btn btn-primary mt-5" to={`/sign-in`}>Sign up to get alerts</Link>
			</div>
		);
	} else if (isPropertyInPortfolio) {
		content = (
			<div className="alerts-card-content d-flex">
				<AlertsList />
			</div>
		);
 	} else {
		content = (
			<div className="alerts-card-content d-flex justify-content-center">
				<Link className="btn btn-primary mt-5" to={`/property/${propertyData?.address}/notifications`}>Get alerts</Link>
			</div>
		);
 	}

	return (
		<div className="overview-card overview-card-small">
			<div className="overview-card-content">
				<div className="fw-bold mb-3">Alerts and Notifications</div>
				{content}
			</div>
		</div>
	);
}

export default AlertsCard;
