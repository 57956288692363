// old ga_id
// const GA_ID = 'G-6NN7KR1XWD';

// new ga_id
const GA_ID = 'G-Q6CT21DK82';

const isProd = () => {
	return window.location.hostname === 'violerts.com';
};

function gtag() {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(arguments);
}

export const initGoogleAnalytics = () => {
	if (isProd()) {
		var script = document.createElement('script');
		script.onload = function () {
			window.dataLayer = window.dataLayer || [];
			gtag('js', new Date());
			gtag('config', GA_ID);
		};
		script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;

		document.head.appendChild(script);
	} else {
		console.log('Google analytics disabled in dev mode.');
	}
};

export const trackPageView = (path) => {
	if (isProd() && path) {
		gtag('event', 'page_view', {
			page_location: path
		});
	}
}