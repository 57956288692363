export const getPropertyLinks = (property, zoning) => {
	let zoningMapNum = property?.zoning_map_number || zoning?.zonemap;
	return {
		"Acris": {
			uri: "https://a836-acris.nyc.gov/DS/DocumentSearch/BBLResult?max_rows=99",
			fields: {
				hid_borough: property?.borough,
				hid_borough_name: property?.borough,
				hid_block: property?.block,
				hid_block_value: property?.block,
				hid_lot: property?.lot,
				hid_lot_value: property?.lot,
				hid_doctype: "All Document Classes",
				hid_doctype_name: "All Document Classes",
				hid_max_rows: 10,
				hid_selectdate: "To Current Date",
				hid_page: 1,
				hid_SearchType: "BBL"
			}
		},
		"BSA Actions (1916-1997)": "https://www1.nyc.gov/site/bsa/applications/docketbook.page",
		"BSA Active Applications": "https://www1.nyc.gov/site/bsa/applications/active-applications.page",
		"BSA Decisions": "https://www1.nyc.gov/site/bsa/applications/bsa-decisions.page",
		"BSA Decisions Map": "https://www1.nyc.gov/site/bsa/applications/decisions-map.page",

		//from property certs
		"Certificate of Occupancy": `https://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=${property?.bin}`,

		"Cyclomedia Street View": "https://roadview.planninglabs.nyc/view/"+ property?.long +"/" + property?.lat,
		"Deed": `https://a836-acris.nyc.gov/DS/DocumentSearch/BBL?borough=${property?.borough}&hid_block=${property?.block}&hid_lot=${property?.lot}&hid_doctype=ALL_DEED`,
		"DEP Boiler": "https://a826-web01.nyc.gov/DEP.BoilerInformationExt/",
		"DOB BIS": `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${property?.borough}&houseno=${property?.house_number}&street=${property?.street_name}`,
		"DOB NOW": "https://a810-dobnow.nyc.gov/publish/Index.html",
		"DOF Assessment Roll": "https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=address",
		"DOF Digital Tax Map": property?.bbl == null ? null : "http://maps.nyc.gov/taxmap/map.htm?searchType=BblSearch&featureTypeName=EVERY_BBL&featureName=" + property?.bbl,
		"DOH": "https://a816-hlst.nyc.gov/CitizenAccess/",
		"DOT": "https://www.nycstreets.net/Public/SignIn/Index",
		
		"FDNY": "https://fires.fdnycloud.org/CitizenAccess/Default.aspx",
		"FEMA Flood Map": "https://msc.fema.gov/portal/search?AddressQuery=" + property?.address + "#searchresultsanchor",
		"HPD": "https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1=" + property?.borough + "&p2=" + property?.house_number + "&p3=" + property?.street_name,

		//from property certs
		"I-Card": `https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1=${property?.borough}&p2=${property?.house_number}&p3=${property?.street_name}`,

		//from property certs
		"Landmark Designation Report": "https://www1.nyc.gov/site/lpc/designations/designation-reports.page",

		"Landmark": "https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b&marker="+ property?.long +"%2C"+ property?.lat +"%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22%22%2C%22longitude%22%3A" + property?.long + "%2C%22latitude%22%3A"+ property?.lat +"%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18",
		"NYC Business License or Permit": "https://www1.nyc.gov/nycbusiness/status/licenses-permits",
		"NYC City Pay Violations": "https://a836-citypay.nyc.gov/citypay/ecb#!/name-address-form",
		"NYC DOB Codes": "https://www.nyc.gov/site/buildings/codes/codes.page",
		"OER": "https://a002-epic.nyc.gov/",
		"Pops": "https://capitalplanning.nyc.gov/map/facilities#10/40.7128/-74.0807",
		"Rent Regulation": "https://apps.hcr.ny.gov/BuildingSearch/",
		"ZoLa Map": `https://zola.planning.nyc.gov/l/lot/${property?.borough}/${property?.block}/${property?.lot}?aerial-year=aerials-2016&layer-groups=%5B%22building-footprints%22%2C%22commercial-overlays%22%2C%22street-centerlines%22%2C%22subway%22%2C%22tax-lots%22%2C%22zoning-districts%22%5D&print=false&search=true&selectedFirm=%5B%22A%22%2C%22Shaded%20X%22%2C%22V%22%5D&selectedOverlays=%5B%22C1-1%22%2C%22C1-2%22%2C%22C1-3%22%2C%22C1-4%22%2C%22C1-5%22%2C%22C2-1%22%2C%22C2-2%22%2C%22C2-3%22%2C%22C2-4%22%2C%22C2-5%22%5D&selectedPfirm=%5B%22A%22%2C%22Shaded%20X%22%2C%22V%22%5D&selectedZoning=%5B%22BP%22%2C%22C1%22%2C%22C2%22%2C%22C3%22%2C%22C4%22%2C%22C5%22%2C%22C6%22%2C%22C7%22%2C%22C8%22%2C%22M1%22%2C%22M2%22%2C%22M3%22%2C%22PA%22%2C%22R1%22%2C%22R10%22%2C%22R2%22%2C%22R3%22%2C%22R4%22%2C%22R5%22%2C%22R6%22%2C%22R7%22%2C%22R8%22%2C%22R9%22%5D&shouldRefresh=false`,
		"Zoning Map": zoningMapNum == null ? null : "https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map" + zoningMapNum?.toLowerCase() + ".pdf",

		"2022 Construction Codes": "https://www.nyc.gov/site/buildings/codes/2022-construction-codes.page",
	};
};

export const getQuickLinks = (property) => {
	return {
		"2022 Construction Codes": "https://www.nyc.gov/site/buildings/codes/2022-construction-codes.page",
		"BSA Actions (1916-1997)": "https://www1.nyc.gov/site/bsa/applications/docketbook.page",
		// "BSA Active Applications": "https://www1.nyc.gov/site/bsa/applications/active-applications.page",
		// "BSA Decisions": "https://www1.nyc.gov/site/bsa/applications/bsa-decisions.page",
		"DEP Boiler": "https://a826-web01.nyc.gov/DEP.BoilerInformationExt/",
		"DOB BIS": `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${property?.borough}&houseno=${property?.house_number}&street=${property?.street_name}`,
		"DOB NOW": "https://a810-dobnow.nyc.gov/publish/Index.html",
		"DOF Assessment Roll": "https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=address",
		"DOH": "https://a816-hlst.nyc.gov/CitizenAccess/",
		"DOS": "https://apps.dos.ny.gov/publicInquiry/#search",
		"DOT": "https://www.nycstreets.net/Public/SignIn/Index",
		"FDNY": "https://fires.fdnycloud.org/CitizenAccess/Default.aspx",
		// "HPD": "https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1=" + property?.borough + "&p2=" + property?.house_number + "&p3=" + property?.street_name,
		"Historic Images": `https://nycma.lunaimaging.com/luna/servlet/view/search?search=SUBMIT&cat=0&q=block%3D${property?.block}+AND+lot%3D${property?.lot}+AND+borough%3D${property?.borough}`,
		"HPD": "https://hpdonline.nyc.gov/hpdonline/building/" + property?.hpd_building_id + "/overview",
		"Landmark": "https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b&marker="+ property?.long +"%2C"+ property?.lat +"%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22%22%2C%22longitude%22%3A" + property?.long + "%2C%22latitude%22%3A"+ property?.lat +"%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18",
		"Landmark Records Request": "https://www.nyc.gov/assets/lpc/downloads/pdf/forms/appointment_request_form.pdf",
		"NYC Business License or Permit": "https://www1.nyc.gov/nycbusiness/status/licenses-permits",
		"NYC City Pay Violations": "https://a836-citypay.nyc.gov/citypay/ecb#!/name-address-form",
		"NYC Department of Consumer Affairs (DCA)": "https://www.nyc.gov/site/doh/health/health-topics/consumer-affairs.page",
		"NYC Department of Design and Construction (DDC)": "https://www.nyc.gov/site/ddc/about/about-ddc.page",
		"NYC DOB Codes": "https://www.nyc.gov/site/buildings/codes/codes.page",
		"NYC Loft Board": "https://www.nyc.gov/site/loftboard/index.page",
		"NYC Office of Environmental Remediation (OER)": "https://www.nyc.gov/site/oer/index.page",
		"OER EPIC": "https://a002-epic.nyc.gov/",
		"Permit Search": "https://www.nyc.gov/site/lpc/applications/permit-search.page",
		"Pops": "https://capitalplanning.nyc.gov/map/facilities#10/40.7128/-74.0807",
		"Rent Regulation": "https://apps.hcr.ny.gov/BuildingSearch/",
	};
};

export const getQuickLinksV2 = (property) => {
	return {
		
		"BSA Actions (1916-1997)": "https://www1.nyc.gov/site/bsa/applications/docketbook.page",
		"DEP Boiler": "https://a826-web01.nyc.gov/DEP.BoilerInformationExt/",
		"DOB BIS": `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${property?.borough}&houseno=${property?.house_number}&street=${property?.street_name}`,
		"DOB NOW": "https://a810-dobnow.nyc.gov/publish/Index.html",
		"DOF Assessment Roll": "https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=address",
		"DOH": "https://a816-hlst.nyc.gov/CitizenAccess/",
		"DOS": "https://apps.dos.ny.gov/publicInquiry/#search",
		"DOT": "https://www.nycstreets.net/Public/SignIn/Index",
		"FDNY": "https://fires.fdnycloud.org/CitizenAccess/Default.aspx",
		"Historic Images": `https://nycma.lunaimaging.com/luna/servlet/view/search?search=SUBMIT&cat=0&q=block%3D${property?.block}+AND+lot%3D${property?.lot}+AND+borough%3D${property?.borough}`,
		"HPD": "https://hpdonline.nyc.gov/hpdonline/building/" + property?.hpd_building_id + "/overview",
		"Landmark": "https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b&marker="+ property?.long +"%2C"+ property?.lat +"%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22%22%2C%22longitude%22%3A" + property?.long + "%2C%22latitude%22%3A"+ property?.lat +"%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18",
		"Landmark Records Request": "https://www.nyc.gov/assets/lpc/downloads/pdf/forms/appointment_request_form.pdf",
		"NYC Business License or Permit": "https://www1.nyc.gov/nycbusiness/status/licenses-permits",
		"NYC City Pay Violations": "https://a836-citypay.nyc.gov/citypay/ecb#!/name-address-form",
		"NYC Department of Consumer Affairs (DCA)": "https://www.nyc.gov/site/doh/health/health-topics/consumer-affairs.page",
		"NYC Department of Design and Construction (DDC)": "https://www.nyc.gov/site/ddc/about/about-ddc.page",
		"NYC DOB Codes": "https://www.nyc.gov/site/buildings/codes/codes.page",
		"NYC Loft Board": "https://www.nyc.gov/site/loftboard/index.page",
		"NYC Maps Now & Then": "https://nyc.maps.arcgis.com/apps/instant/media/index.html?appid=e011fd05a86a4c09bd0b91fbc387f3eb",
		"NYC Office of Environmental Remediation (OER)": "https://www.nyc.gov/site/oer/index.page",
		"OER EPIC": "https://a002-epic.nyc.gov/",
		"Permit Issuances": `/property/${property?.address}/dobPermitIssuancesOpenData`,
		"Permit Search": "https://www.nyc.gov/site/lpc/applications/permit-search.page",
		"Pops": "https://capitalplanning.nyc.gov/map/facilities#10/40.7128/-74.0807",
		"Rent Regulation": "https://apps.hcr.ny.gov/BuildingSearch/",
	};
};

export const getMapLinks = (property, zoning) => {
	let zoningMapNum = property?.zoning_map_number || zoning?.zonemap;
	return {
		"BSA Decisions Map": "https://www1.nyc.gov/site/bsa/applications/decisions-map.page",
		"Cyclomedia Street View": "https://roadview.planninglabs.nyc/view/"+ property?.long +"/" + property?.lat,
		"DOF Digital Tax Map": property?.bbl == null ? null : "http://maps.nyc.gov/taxmap/map.htm?searchType=BblSearch&featureTypeName=EVERY_BBL&featureName=" + property?.bbl,
		"FEMA Flood Map": "https://msc.fema.gov/portal/search?AddressQuery=" + property?.address + "#searchresultsanchor",
		"ZoLa Map": `https://zola.planning.nyc.gov/l/lot/${property?.borough}/${property?.block}/${property?.lot}?aerial-year=aerials-2016&layer-groups=%5B%22building-footprints%22%2C%22commercial-overlays%22%2C%22street-centerlines%22%2C%22subway%22%2C%22tax-lots%22%2C%22zoning-districts%22%5D&print=false&search=true&selectedFirm=%5B%22A%22%2C%22Shaded%20X%22%2C%22V%22%5D&selectedOverlays=%5B%22C1-1%22%2C%22C1-2%22%2C%22C1-3%22%2C%22C1-4%22%2C%22C1-5%22%2C%22C2-1%22%2C%22C2-2%22%2C%22C2-3%22%2C%22C2-4%22%2C%22C2-5%22%5D&selectedPfirm=%5B%22A%22%2C%22Shaded%20X%22%2C%22V%22%5D&selectedZoning=%5B%22BP%22%2C%22C1%22%2C%22C2%22%2C%22C3%22%2C%22C4%22%2C%22C5%22%2C%22C6%22%2C%22C7%22%2C%22C8%22%2C%22M1%22%2C%22M2%22%2C%22M3%22%2C%22PA%22%2C%22R1%22%2C%22R10%22%2C%22R2%22%2C%22R3%22%2C%22R4%22%2C%22R5%22%2C%22R6%22%2C%22R7%22%2C%22R8%22%2C%22R9%22%5D&shouldRefresh=false`,
		"Zoning Map": zoningMapNum == null ? null : "https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map" + zoningMapNum?.toLowerCase() + ".pdf",
	};
};

export const getDownloadLinks = (property) => {
	return {
		"Acris": {
			uri: "https://a836-acris.nyc.gov/DS/DocumentSearch/BBLResult?max_rows=99",
			fields: {
				hid_borough: property?.borough,
				hid_borough_name: property?.borough,
				hid_block: property?.block,
				hid_block_value: property?.block,
				hid_lot: property?.lot,
				hid_lot_value: property?.lot,
				hid_doctype: "All Document Classes",
				hid_doctype_name: "All Document Classes",
				hid_max_rows: 10,
				hid_selectdate: "To Current Date",
				hid_page: 1,
				hid_SearchType: "BBL"
			}
		},
		"Approved Permits": `/property/${property?.address}/approvedPermitsOpenData`,
		"Certificate of Occupancy": `https://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=${property?.bin}`,
		"Deed": `https://a836-acris.nyc.gov/DS/DocumentSearch/BBL?borough=${property?.borough}&hid_block=${property?.block}&hid_lot=${property?.lot}&hid_doctype=ALL_DEED`,
		"FDNY Report": "FDNY Report",
		"I-Card": `https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1=${property?.borough}&p2=${property?.house_number}&p3=${property?.street_name}`,
		"Landmark Designation Report": "https://www1.nyc.gov/site/lpc/designations/designation-reports.page",
		"Permit Issuances": `/property/${property?.address}/dobPermitIssuancesOpenData`,
	};
};


// getting rid of this column;
export const getDocumentDownloadLinks = (property) => {
	return {
		// "Acris": {
		// 	uri: "https://a836-acris.nyc.gov/DS/DocumentSearch/BBLResult?max_rows=99",
		// 	fields: {
		// 		hid_borough: property?.borough,
		// 		hid_borough_name: property?.borough,
		// 		hid_block: property?.block,
		// 		hid_block_value: property?.block,
		// 		hid_lot: property?.lot,
		// 		hid_lot_value: property?.lot,
		// 		hid_doctype: "All Document Classes",
		// 		hid_doctype_name: "All Document Classes",
		// 		hid_max_rows: 10,
		// 		hid_selectdate: "To Current Date",
		// 		hid_page: 1,
		// 		hid_SearchType: "BBL"
		// 	}
		// },
		// "Approved Permits": `/property/${property?.address}/approvedPermitsOpenData`,
		"Certificate of Occupancy": `https://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=${property?.bin}`,
		"Deed": `https://a836-acris.nyc.gov/DS/DocumentSearch/BBL?borough=${property?.borough}&hid_block=${property?.block}&hid_lot=${property?.lot}&hid_doctype=ALL_DEED`,
		// "FDNY Report": "FDNY Report",
		"I-Card": `https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1=${property?.borough}&p2=${property?.house_number}&p3=${property?.street_name}`,
		"Landmark Designation Report": "https://www1.nyc.gov/site/lpc/designations/designation-reports.page",
		// "Permit Issuances": `/property/${property?.address}/dobsermitIssuancesOpenData`,
	};
};


export const codesLinks = {
	'Building Code': 'https://www.nyc.gov/site/buildings/codes/nyc-code.page',
	'2022 Construction Code': 'https://www.nyc.gov/site/buildings/codes/2022-construction-codes.page',
	'NYC Accessibility Code': 'https://www.nyc.gov/assets/buildings/apps/pdf_viewer/viewer.html?file=2022BC_Chapter11_AccessibilityWBwm.pdf&section=conscode_2022',
	'ADA Standards': 'https://www.nyc.gov/assets/buildings/apps/pdf_viewer/viewer.html?file=2022BC_Chapter11_AccessibilityWBwm.pdf&section=conscode_2022',
	'CMS': 'https://www.cms.gov/medicare/health-safety-standards/conditions-coverage-participation/life-safety-code',
	'NYC Fire Sprinkler Code': 'https://www.nyc.gov/site/buildings/industry/project-requirements-design-professional-sprinkler.page',
	'NYC Fire Code': 'https://www.nyc.gov/site/fdny/codes/fire-code/fire-code.page',
	'NYC Structural Concrete Code': 'https://www.nyc.gov/html/dob/downloads/bldgs_code/bcrs10.pdf',
	'NYC Energy Code': 'https://www.nyc.gov/site/buildings/codes/energy-conservation-code.page',
	'NYC General Admin Provisions': 'https://codes.iccsafe.org/content/NYNYGAPCC2022P1/chapter-1-administration',
	'NYC Zoning Resolution': 'https://zr.planning.nyc.gov/',
	'NYC Title 1/2/3': 'https://www.nyc.gov/site/buildings/codes/title-1-rules-of-the-city-of-new-york.page',
	'NYC Housing Maintenance': 'https://www.nyc.gov/assets/buildings/pdf/HousingMaintenanceCode.pdf',
	'Multiple Dwelling Law': 'https://www.nyc.gov/assets/buildings/pdf/MultipleDwellingLaw.pdf',
	'Fair Housing Act': 'https://www.nyc.gov/site/fairhousing/resources/fair-housing-materials.page',
	'OSHA': 'https://www.nyc.gov/site/buildings/safety/osha-requirements.page',
	'Permit Exceptions': 'https://www.nyc.gov/assets/buildings/rules/1_RCNY_101-14.pdf',
	'Directives & Memoranda': 'https://www.nyc.gov/site/buildings/codes/directives-memoranda.page',
	'Building Bulletins and Construction Advisories': 'https://www.nyc.gov/site/buildings/codes/building-bulletins.page',
	'Policy and Procedure Notices': 'https://www.nyc.gov/site/buildings/codes/policy-procedure-notices.page',	
};

export const resourcesLinks = {
	'Permit Exceptions': 'https://www.nyc.gov/assets/buildings/rules/1_RCNY_101-14.pdf',
	'Directives & Memoranda': 'https://www.nyc.gov/site/buildings/codes/directives-memoranda.page',
	'Building Bulletins and Construction Advisories': 'https://www.nyc.gov/site/buildings/codes/building-bulletins.page',
	'Policy and Procedure Notices': 'https://www.nyc.gov/site/buildings/codes/policy-procedure-notices.page',	
};

export const VENDOR_LINKS = {
	BVS: 'https://bvs.nyc',
	AAPC: 'https://aapc.nyc',
	ARKCO: 'https://arkco.nyc',
	VPS: 'https://vps.nyc',
	AzTitle: 'https://az-title.com',
	AZARK: 'https://azark.com'
}

export const vendorsLinks = {
	'Expeditors': VENDOR_LINKS.BVS,
	'Architects': VENDOR_LINKS.AAPC,
	'Contractors': VENDOR_LINKS.ARKCO,
	'Printing Solutions': VENDOR_LINKS.VPS,
	'Title': VENDOR_LINKS.AzTitle,
	'Management': VENDOR_LINKS.AZARK
};


export default getPropertyLinks;