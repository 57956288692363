import StandardModal from 'modals/StandardModal';

import './IframeModal.css';

const IframeModal = ({ options }) => {
	return (
		<StandardModal
			title={options?.title || ""}
			closeOnOutsideClick
			noFooter
			className="iframe-modal"
			size="max"
		>
			<div className="text-center">
				<iframe
					className="modal-iframe"
					title={options?.title || 'Modal iFrame'}
					src={options?.src || options?.url}
				/>
			</div>
		</StandardModal>
	);
};

export default IframeModal;
