import { useState } from "react";
import { Link } from "react-router-dom";

import AddressSearch from 'components/addressSearch/AddressSearch';
// import Logo from 'components/appHeader/Logo';
import heroSearch from '../images/hero-image-2.png'
import heroAlerts from '../images/heroAlerts.png'
import heroCorrections from '../images/heroCorrections.png'
import heroReports from '../images/heroReports.png'
import heroBg from '../images/heroBg.png';
import './hero.css';


const Hero = () => {


    const content = {
        Search: ['Search', 'PropTech for Effortless Property Compliance', heroSearch],
        Reports: ['Reports', 'Streamlined Information, Simplified Decisions with our all-inclusive dashboard', heroReports,],
        Alerts: ['Alerts', 'Keeping You and Your Property in the Loop', heroAlerts],
        Corrections: ['Correction', 'Don\'t Sweat the Fix. Get Compliant Fast with Our Partners', heroCorrections],
    }

    const [text/*, setText*/] = useState(content['Search'][1]);


    const search = (
        <div className="rd-landing-page-search">
            {/*<Logo/>*/}
        		<img src="/violerts-logo-beta-2.png" className="logo logo-beta" alt="violerts" />
            <div className="tagline">Property compliance just got easier</div>

            <div className="address-search-wrapper">
                <AddressSearch
                    addressSearchLabel="Find an address"
                    hideLabel
                />
            </div>
        </div>
    );

    // magic() changes the displayed picture
    // function changePic(cat) {

    //     // select all hero images and remove active-img class
    //     const els = document.getElementsByClassName('hero-img');
    //     for (const el of els) {
    //         el.classList.remove("active-img");
    //     }
    //     // add active class to current argument
    //     const currEl = document.getElementById(cat);
    //     currEl.classList.add("active-img");

    //     setText(content[cat][1]);
    // }


    return (
        <div className="hero">
            <div className='hero-left'>
                {search}
            </div>
            <div className='hero-right'>
                {/*<div className='carousel-nav'>*/}
                {/*    <a onClick={() => changePic('Search')}>Search</a>*/}
                {/*    <a onClick={() => changePic('Reports')}>Reports</a>*/}
                {/*    <a onClick={() => changePic('Alerts')}>Alerts</a>*/}
                {/*    <a onClick={() => changePic('Corrections')}>Corrections</a>*/}
                {/*</div>*/}
            		
                <div className='hero-carousel'>
                    <Link to="/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/overview"><img src={heroSearch} alt="heroSearch" className="hero-img active-img" id='Search' draggable="false"/></Link>
                    <Link to="/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/overview"><img src={heroReports} alt="heroReports" className="hero-img" id='Reports' draggable="false"/></Link>
                    <Link to="/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/overview"><img src={heroAlerts} alt="heroAlerts" className="hero-img" id='Alerts' draggable="false"/></Link>
                    <Link to="/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/overview"><img src={heroCorrections} alt="heroCorrections" className="hero-img" id='Corrections' draggable="false"/></Link>
                    <Link to="/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/overview"><img src={heroBg} alt="heroBg" className="hero-img" style={{position: 'relative', opacity: 1, zIndex: -1}}/></Link>
                    <div className='carousel-text'>
                        {/*<h1>*/}
                        {/*    /!*{title}*!/*/}
                        {/*</h1>*/}
                        <p>{text}</p></div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
