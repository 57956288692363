import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { /*fetchPortfolioSettings,*/ fetchPortfolioSettingsByPropertyId, setNotification/*, removeProperty*/ } from 'reducers/portfolioSettingsReducer';
// import { fetchPortfolioSettingsByPropertyId, setNotification } from 'reducers/portfolioSettingsReducer';
// import { fetchPortfolio } from 'reducers/portfolioReducer';
// import { closeModal } from 'reducers/modalReducer';
// import StandardModal from 'modals/StandardModal';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

import './AlertsList.css';

const AlertsList = ({ showAddress }) => {
	const dispatch = useDispatch();
	// const { portfolioId } = options; //todo: how to get this?
	const {
		portfolioSettings,
		isLoading,
		propertyData,
		userId,
	} = useSelector(state => ({
		isLoading: state.portfolioSettings.isLoading || state.portfolioSettings.isRemovingProperty,
		portfolioSettings: state.portfolioSettings.data || {},
		propertyData: state.property.propertyData,
		userId: state.user.userData?.id,
	}));

	// useEffect(() => {
	// 	dispatch(fetchPortfolioSettings(portfolioId));
	// }, [portfolioId, dispatch]);


	useEffect(() => {
		if (propertyData?.id && userId) {
			dispatch(fetchPortfolioSettingsByPropertyId(propertyData?.id));
		}
	}, [propertyData, userId, dispatch]);

	const handleToggle = (notificationId, isEnabled) => {
		dispatch(setNotification(portfolioSettings.id, notificationId, isEnabled));
	};

	const getLabel = (notificationType) => {
		let labelText = notificationType?.name;
		if (notificationType?.dataSource === 'BIS') {
			labelText += ' (BIS)';
		}
		return labelText;
	};

	if (isLoading) {
		return (
			<div className="alerts-list d-flex justify-content-center w-100">
				<Spinner />
			</div>
		);
	}

	return (
		<div className="alerts-list">
			{showAddress && <h6>{propertyData?.address}</h6>}
			{/*<div className="mb-2">Notifications:</div>*/}
			<div>
				{portfolioSettings.notificationTypes?.map(notificationType => (
					<Toggle
						key={notificationType.id}
						label={getLabel(notificationType)}
						isEnabled={notificationType.enabled === 1}
						onChange={(isEnabled) => handleToggle(notificationType.id, isEnabled)}
					/>
				))}
			</div>
		</div>
	);
};

export default AlertsList;
